
.myloader{
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background: url('./loading-logo.gif') 
                50% 50% no-repeat white;
  }