
.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: rgb(250, 250,250);
}

.image {
  width: 370px;
  margin-top: 50px;
}

.customui {
  
  position: fixed;
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 0vh;
	overflow: hidden;
	
	
  
}

.body {
  
  position: relative;
	left: 20px;
	align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 80px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}


.body2 {
  
  position: relative;
	left: 30px;
	align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  width: 100%;
  padding: 40px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}


.body3 {
  
  padding: 80px;
  
  width: 100%;
  
  position: relative;
	align-items: center;
  font-family: Arial, Helvetica, sans-serif;
  background: #fff;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}



.button {
  background-color: #3f51bf; /* Green */
  border: none;
  color: white;
  padding: 8px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px 5px;
  cursor: pointer;
  border-radius: 6px;
}

.button1 {background-color: #008CBA; } /* Blue */
.button2 {background-color: #008CBA; } /* Blue */
.button3 {background-color: #f44336;} /* Red */ 
.button4 {background-color: #e7e7e7; color: black;} /* Gray */ 
.button5 {background-color: #555555;} /* Black */





